<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
		></pui-datatable>
		<mpuertolocal-modals :modelName="modelName"></mpuertolocal-modals>
	</div>
</template>

<script>
import mpuertolocalActions from './MpuertolocalActions';
import mpuertolocalModals from './MpuertolocalModals.vue';

export default {
	name: 'mpuertolocal-grid',
	components: {
		'mpuertolocal-modals': mpuertolocalModals
	},
	data() {
		return {
			modelName: 'mpuertolocal',
			actions: mpuertolocalActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
